import { getTicketDuration } from './ticket-duration';
interface Ticket {
    canActivateFrom: string;
    canActivateTo: string;
    changeAllowed: string;
    config: any;
    customDurationRange: CustomDurationRange | null;
    customDurationRangeId: string;
    displayedFrom: string;
    displayedTo: string;
    duration: string;
    validFrom: string;
    validTo: string;
    id: string;
    inSaleFrom: string;
    inSaleTo: string;
    isBearer: string;
    metadata: Metadata;
    name: string;
    priceWithTax: string;
    priceWithoutTax: string;
    referenceNumber: string;
    transportAuthorityId: string;
    tags: Tag[];
    transportLines: TransportLine[];
    transportZones: TransportZone[];
    createdAt: string;
    updatedAt: string;
}

interface Tag {
    id: string;
    name: string;
    tagGroupId: string;
    transportAuthorityId: string;
    createdAt: string;
    updatedAt: string;
}

interface TransportLine {
    id: string;
    name: string;
    transportAuthorityId: string;
    createdAt: string;
    updatedAt: string;
}

interface TransportZone {
    id: string;
    name: string;
    transportAuthorityId: string;
    referenceNumber: string;
}

interface CustomDurationRange {
    count: string;
    durationDescription: string;
    fromDate: string[];
    fromDay: string[];
    id: string;
    name: string;
    toDate: string[];
    toDay: string[];
    transportAuthorityId: string;
    type: string;
    createdAt: string;
    updatedAt: string;
}

interface Metadata {
    durationDescription?: string;
    detailedDescription?: string;
    zoneDescription?: string;
    priceDescription?: string;
}

const millisecondsMap = [
    {
        type: `seconds`,
        value: 1000,
    },
    {
        type: `minutes`,
        value: 60000,
    },
    {
        type: `hours`,
        value: 3600000,
    },
    {
        type: `days`,
        value: 86400000,
    },
    {
        type: `years`,
        value: 31557600000,
    },
];

export const calculateViewTicketZone = (ticket: Ticket) => {
    let zone = ``;

    if (ticket.metadata.zoneDescription) {
        return ticket.metadata.zoneDescription;
    }

    if (ticket.transportZones.length > 0) {
        zone = ticket.transportZones
            .map((el) => {
                return el.name;
            })
            .join(`, `);
    }

    return zone;
};

export const calculateViewTicketDuration = (ticket: Ticket) => {
    if (ticket.duration && ticket.duration.length > 0) {
        const splitDuration = ticket.duration.split(` `);
        const msObj = millisecondsMap.find((el) => {
            return el.type === splitDuration[1];
        });

        if (splitDuration[1] === `months`) {
            return `${splitDuration[0]} msc`;
        }

        if (!msObj) {
            return ``;
        }

        return getTicketDuration(msObj.value * Number(splitDuration[0]));
    }

    if (ticket.customDurationRange) {
        return ticket.customDurationRange.name;
    }
};
